"use client"

import { useTheme } from "@mui/material/styles"
import { type HTMLAttributes, useEffect, useState } from "react"

import { Skeleton } from "@/components/ui/feedback/Skeleton"
import { Box } from "@/components/ui/layout/Box"

import { ListItemText } from "@/components/ui/data-display/ListItemText"
import { Typography } from "@/components/ui/data-display/Typography"
import Icon from "@mdi/react"
import { Container, ListItem } from "./DrawerListItem.styled"
import Link from "next/link"
import type { Route } from "next"
import useAside from "../Aside/hooks/useAside"

export interface DrawerListItemProps
  extends Omit<HTMLAttributes<HTMLAnchorElement>, "onClick"> {
  href: Route
  text?: string
  icon?: string
  iconRotation?: number
  selected?: boolean
  drawerOpen?: boolean
  loading?: boolean
  onClick?: (href: string) => void
}

export default function DrawerListItem({
  href,
  icon,
  iconRotation,
  text,
  selected,
  drawerOpen,
  loading,
  onClick,
  ...attributes
}: DrawerListItemProps) {
  const theme = useTheme()
  const { asideSearch } = useAside()

  const [isSelected, setIsSelected] = useState(selected)
  const [isDrawerOpen, setIsDrawerOpen] = useState(drawerOpen)

  useEffect(() => setIsSelected(selected), [selected])
  useEffect(() => setIsDrawerOpen(drawerOpen), [drawerOpen])

  return (
    <Link
      href={`${href}?${asideSearch}` ?? ""}
      className="no-underline"
      onClick={() => onClick?.(href)}
      {...attributes}
    >
      <ListItem
        selected={isSelected}
        className={selected ? "selected" : ""}
        sx={{ height: theme.spacing(4.5), display: "flex", padding: 0 }}
      >
        <Container open={isDrawerOpen}>
          <Box
            className="h-full"
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              color={theme.palette.text.secondary}
              sx={{
                minWidth: isDrawerOpen ? theme.spacing(7) : undefined,
              }}
              display="flex"
              alignItems="center"
            >
              {!loading && icon ? (
                <Icon
                  size={theme.spacing(3)}
                  path={icon}
                  style={{
                    transform: iconRotation ? `rotate(${iconRotation}deg)` : "",
                  }}
                />
              ) : (
                <Skeleton
                  variant="circular"
                  width={theme.spacing(3)}
                  height={theme.spacing(3)}
                />
              )}
            </Box>
            {isDrawerOpen &&
              (!loading && text ? (
                <ListItemText
                  sx={{
                    padding: `${theme.spacing(0.5)} 0`,
                    margin: 0,
                  }}
                >
                  <Typography variant="body2" color="textPrimary">
                    {text}
                  </Typography>
                </ListItemText>
              ) : (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height={theme.spacing(1.5)}
                />
              ))}
          </Box>
        </Container>
      </ListItem>
    </Link>
  )
}
