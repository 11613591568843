import { trpc } from "@/app/_trpc/client"
import { Badge } from "@/components/ui/data-display/Badge"
import { Button } from "@/components/ui/inputs/Button"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { Menu } from "@/components/ui/navigation/Menu"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { Toolbar } from "@/components/ui/surfaces/Toolbar"
import { Collapse } from "@/components/ui/utils/Collapse"
import { useTranslation } from "@/i18n"
import { mdiForum } from "@mdi/js"
import Icon from "@mdi/react"
import de from "../../_messages/de.json"
import MessageNotificationList from "../MessageNotificationList"
import { useTheme } from "@mui/material/styles"

const maxWidth = 400

export default function MessageNotificationIconButton() {
  const theme = useTheme()
  const { t } = useTranslation(de)
  const { openMenu, closeMenu, menuProps } = useMenu()

  const checkNotificationsQuery = trpc.messages.checkNotifications.useQuery(
    {},
    {
      refetchInterval: 10_000,
    },
  )

  const count = checkNotificationsQuery?.data?.count ?? 0
  const unread = count > 0

  if (checkNotificationsQuery.error) {
    throw checkNotificationsQuery.error
  }
  return (
    <>
      <IconButton
        data-testid={"chat-notification-icon-button"}
        data-testid-unread={unread ? "true" : "false"}
        size="small"
        onClick={openMenu}
      >
        <Badge badgeContent={count} variant="dot" color="primary">
          <Icon path={mdiForum} size={1} />
        </Badge>
      </IconButton>

      <Menu
        data-testid="message-notification-menu"
        className="w-full"
        {...menuProps}
        slotProps={{
          paper: {
            sx: {
              width: "100%",
              maxWidth: maxWidth,
            },
          },
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <Collapse in={menuProps.open} unmountOnExit>
          <Box>
            <MessageNotificationList onClick={closeMenu} maxWidth={maxWidth} />
          </Box>
        </Collapse>
        <Toolbar className="px-3" variant="dense">
          <Box flexGrow={1} />
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button
              data-testid="chat-notification-close-button"
              color="inherit"
              size="small"
              onClick={closeMenu}
              sx={{
                color: theme.palette.action.active,
              }}
            >
              {t("notifictaions.popper.close")}
            </Button>
          </Stack>
        </Toolbar>
      </Menu>
    </>
  )
}
