import { Badge } from "@/components/ui/data-display/Badge"
import { Button } from "@/components/ui/inputs/Button"
import { IconButton } from "@/components/ui/inputs/IconButton"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import { Toolbar } from "@/components/ui/surfaces/Toolbar"
import { useTranslation } from "@/i18n"
import { mdiBell } from "@mdi/js"
import Icon from "@mdi/react"
import { useQueryClient } from "@tanstack/react-query"

import { trpc } from "@/app/_trpc/client"
import { Menu } from "@/components/ui/navigation/Menu"
import useMenu from "@/components/ui/navigation/Menu/useMenu"
import { Collapse } from "@/components/ui/utils/Collapse"
import { getQueryKey } from "@trpc/react-query"
import de from "../../_messages/de.json"
import NotificationList from "../NotificationList"
import { useTheme } from "@mui/material/styles"
import { useCallback } from "react"

const maxWidth = 400

export default function NotificationIconButton() {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const { t } = useTranslation(de)
  const { openMenu, closeMenu, menuProps } = useMenu()

  const notificationCheckQuery = trpc.notification.check.useQuery(undefined, {
    refetchInterval: 10_000,
  })
  const count = notificationCheckQuery.data?.count ?? 0
  const unread = count > 0

  const acknowledgeMutation = trpc.notification.acknowledge.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.list),
      })
      queryClient.invalidateQueries({
        queryKey: getQueryKey(trpc.notification.check),
      })
    },
  })

  const handleAcknowledgeAll = useCallback(() => {
    acknowledgeMutation.mutate({
      id: null,
      includeOrganization: true,
    })
  }, [acknowledgeMutation])

  if (notificationCheckQuery.error) {
    throw notificationCheckQuery.error
  }
  return (
    <>
      <IconButton
        data-testid={"notification-icon-button"}
        data-testid-unread={unread ? "true" : "false"}
        size="small"
        onClick={openMenu}
      >
        <Badge badgeContent={count} variant="dot" color="primary">
          <Icon path={mdiBell} size={1} />
        </Badge>
      </IconButton>

      <Menu
        data-testid="notification-menu"
        {...menuProps}
        slotProps={{
          paper: {
            sx: {
              width: "100%",
              maxWidth: maxWidth,
            },
          },
        }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <Collapse in={menuProps.open} unmountOnExit>
          <NotificationList onClick={closeMenu} maxWidth={maxWidth} />
        </Collapse>
        <Toolbar className="px-3" variant="dense">
          <Box flexGrow={1} />
          <Stack direction="row" alignItems="center" spacing={1}>
            {unread && (
              <Button
                data-testid="notification-acknowledge-all-button"
                color="info"
                size="small"
                onClick={handleAcknowledgeAll}
              >
                {t("notifictaions.popper.acknowledgeAll")}
              </Button>
            )}
            <Button
              data-testid="notification-close-button"
              color="inherit"
              size="small"
              onClick={closeMenu}
              sx={{
                color: theme.palette.action.active,
              }}
            >
              {t("notifictaions.popper.close")}
            </Button>
          </Stack>
        </Toolbar>
      </Menu>
    </>
  )
}
