"use client"

import UserAvatar from "@/app/[locale]/(user)/_components/UserAvatar"
import AppBreadcrumbs, {
  type AppPortalBreadcrumbsProps,
} from "@/app/_components/AppPortalBreadcrumbs"
import useAside from "@/app/_components/Aside/hooks/useAside"
import AsideLoading from "@/app/_components/Aside/loading"
import DrawerListItem from "@/app/_components/DrawerListItem"
import NavGroup, {
  type DrawerNavGroupProps,
} from "@/app/_components/DrawerNavGroup"
import DrawerToggle from "@/app/_components/DrawerToggle"
import { Divider } from "@/components/ui/data-display/Divider"
import { Box } from "@/components/ui/layout/Box"
import { Stack } from "@/components/ui/layout/Stack"
import {
  Drawer,
  DrawerHeader,
  DrawerPaper,
  DrawerWrapper,
} from "@/components/ui/navigation/Drawer/Drawer.styled"
import { Paper } from "@/components/ui/surfaces/Paper"
import { Collapse } from "@/components/ui/utils/Collapse"
import useOptimisticPathname from "@/hooks/useOptimisticPathname"
import { replaceSlugs } from "@/utils/navigation"
import { useTheme } from "@mui/material/styles"
import { useDebounce, useWindowSize } from "@uidotdev/usehooks"
import type { Route } from "next"
import { useParams } from "next/navigation"
import { Suspense, useEffect, useMemo, useState, type ReactNode } from "react"

import MessageNotificationIconButton from "@/app/[locale]/(communication)/_components/MessageNotificationIconButton"
import NotificationIconButton from "@/app/[locale]/(notification)/_components/NotificationIconButton"
import {
  isClerk,
  isUserDataOfLawfirm,
  isUserDataOfServiceProvider,
  isWithoutRole,
} from "@/app/[locale]/(user)/_utils"
import { Button } from "@/components/ui/inputs/Button"
import useBoundingRect from "@/hooks/useBoundingRect"
import { useTranslation } from "@/i18n"
import blueNoiseImage from "@/public/images/blue-noise.png"
import { useGetUserSessionData } from "@/utils/lib"
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js"
import Icon from "@mdi/react"
import { useMediaQuery } from "@mui/material"
import dynamic from "next/dynamic"
import Link from "next/link"
import { Resizable } from "re-resizable"
import AppGlobalSearch from "../AppGlobalSearch"
import DragHandler, { horizontalResizeLeft } from "../DragHandler"
import { LOGO_SIZE_FACTOR, LogoIcon, LogoText } from "../Logo"
import de from "./messages/de.json"
import { trpc } from "@/app/_trpc/client"
import { Alert } from "@/components/ui/feedback/Alert"
import { DebounceDelay } from "@/utils/constants"

const Aside = dynamic(() => import("@/app/_components/Aside"))

export interface AppPortalLayoutProps {
  children?: ReactNode
  navConfigGroups?: { [group: string]: Array<NavConfig> }
  navConfigGroupProps?: { [group: string]: DrawerNavGroupProps }
  appBreadcrumbsProps?: AppPortalBreadcrumbsProps
}

export interface BaseNavConfig {
  key: string
  label?: string
  icon?: string
  iconRotation?: number
  showInNav?: boolean
}

export interface NavConfig extends BaseNavConfig {
  href?: Route
  isFallback?: boolean
  slugName?: string
  slugIcon?: string
  background?: "default" | "blueNoise"
}

export interface SubNavConfig extends BaseNavConfig {
  slugName?: string
  slugIcon?: string
}

export const APP_WRAPPER_ID = "app-wrapper" as const
export const APP_LAYOUT_MAIN_CONTAINER_ID = "main-container" as const
export const APP_LAYOUT_MAIN_CONTENT_ID = "main-content" as const

export default function AppPortalLayout({
  children,
  navConfigGroups = {},
  navConfigGroupProps = {},
  appBreadcrumbsProps = {},
}: AppPortalLayoutProps) {
  const { t } = useTranslation(de)
  const params = useParams()
  const theme = useTheme()
  const windowSize = useWindowSize()
  const { isAsideOpen, asideSearch, asideOptions } = useAside()

  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const userSessionDataQuery = useGetUserSessionData()
  const userData = userSessionDataQuery.data?.data
  const ownUserQuery = trpc.entity.user.getOwn.useQuery(undefined, {
    enabled: !!userData?.userId,
    retry: false,
  })
  const useSimplifiedInterface =
    isUserDataOfServiceProvider(userData) &&
    (isClerk(userData) || isWithoutRole(userData))
  const isLawfirmUser = isUserDataOfLawfirm(userData)

  const isDemoAccount = useMemo(
    () => ownUserQuery.data?.data?.demo === true,
    [ownUserQuery.data?.data?.demo],
  )
  const showDemoBanner = ownUserQuery.isFetched && isDemoAccount
  const debouncedShowDemoBanner = useDebounce(
    ownUserQuery.isFetched ? isDemoAccount : undefined,
    DebounceDelay.TransitionDuration,
  )

  const allNavConfigs = useMemo(
    () => Object.values(navConfigGroups).flat(),
    [navConfigGroups],
  )

  const [
    containerRef,
    containerBoundingRect,
    { reevaluate: reevaluateContainer },
  ] = useBoundingRect<HTMLDivElement>({
    reevaluateAfterMs: 1_000,
  })
  const [contentRef, contentBoundingRect, { reevaluate: reevaluateContent }] =
    useBoundingRect<HTMLDivElement>({
      reevaluateAfterMs: 1_000,
    })

  const [open, setOpen] = useState(true)

  const handleToggleDrawer = () => {
    setOpen((open) => !open)
  }

  const { pathname, updatePathname } = useOptimisticPathname()

  const isServiceProviderDashboardRoute =
    pathname === replaceSlugs("/[locale]/cardealer/[cardealerId]", params)
  const currentNavConfig = useMemo(
    () =>
      allNavConfigs.find((config) =>
        config.key === allNavConfigs?.find((config) => config?.isFallback)?.key
          ? pathname === replaceSlugs(config?.href ?? "", params)
          : pathname?.includes(replaceSlugs(config?.href ?? "", params)),
      ),
    [allNavConfigs, params, pathname],
  )
  const background = currentNavConfig?.background ?? "default"

  useEffect(() => {
    if (isAsideOpen) {
      setOpen(false)
    }
  }, [isAsideOpen])

  useEffect(() => {
    if (isMobile) {
      setOpen(false)
    }
  }, [isMobile])

  useEffect(() => {
    // reevaluate container and content bounding rect when the demo banner is shown or hidden
    // we use the debounced value to wait for the transition to finish
    if (debouncedShowDemoBanner !== undefined) {
      reevaluateContainer()
      reevaluateContent()
    }
  }, [reevaluateContainer, reevaluateContent, debouncedShowDemoBanner])

  return (
    <Stack className="h-lvh overflow-hidden" spacing={0}>
      <Collapse
        in={showDemoBanner}
        style={{
          zIndex: theme.zIndex.modal + 1,
        }}
      >
        <Alert
          className="rounded-none"
          variant="filled"
          severity="info"
          action={
            <Button
              size="small"
              color="inherit"
              href={`mailto:support@rightflow.de?subject=Request upgrade to full version by ${encodeURIComponent(
                (ownUserQuery.data?.data?.email || userData?.email) ?? "",
              )}`}
              endIcon={<Icon size={0.75 /* 18px */} path={mdiArrowRight} />}
            >
              {t("demoBanner.upgradeButton")}
            </Button>
          }
        >
          {t("demoBanner.text")}
        </Alert>
      </Collapse>
      <div
        id={APP_WRAPPER_ID}
        ref={containerRef}
        className="flex flex-row overflow-hidden"
        onScroll={(e) => {
          // disable scroll by always set scroll top to 0 again
          // required to fix chrome glitch when .scrollIntoView() is used (DCP-2392)
          e.preventDefault()
          e.currentTarget.scrollTop = 0
          console.log("scroll prevented")
        }}
      >
        <DrawerWrapper
          id={APP_LAYOUT_MAIN_CONTAINER_ID}
          data-testid="app-portal-layout"
          className="flex overflow-hidden relative h-full"
        >
          {/* left side of the screen */}
          {!useSimplifiedInterface && (
            <Drawer
              className="flex"
              variant="permanent"
              open={open}
              sx={{
                "& .MuiDrawer-paper": {
                  overflow: "hidden",
                },
              }}
            >
              <DrawerPaper
                className="flex-grow overflow-hidden"
                elevation={0}
                square
                style={{
                  top: `${containerBoundingRect.top ?? 0}px`,
                  height: `calc(100vh - ${containerBoundingRect.top ?? 0}px)`,
                }}
              >
                <DrawerHeader data-testid={"DrawerHeader"}>
                  <DrawerToggle open={open} onChange={handleToggleDrawer} />
                </DrawerHeader>
                <Divider />
                <Box
                  data-testid="NavContainer"
                  className="overflow-y-auto"
                  style={{
                    height: `calc(100vh - ${contentBoundingRect.top ?? 0}px)`,
                  }}
                  component="nav"
                >
                  <Stack spacing={theme.spacing(1.25)} p={1} pb={10}>
                    {Object.entries(navConfigGroups).map(
                      ([groupName, navConfig]) => (
                        <NavGroup
                          key={groupName}
                          groupId={groupName}
                          drawerOpen={open}
                          {...(navConfigGroupProps[groupName] ?? {})}
                        >
                          {navConfig
                            .filter((config) => config.showInNav !== false)
                            .map((config) => (
                              <DrawerListItem
                                key={config.key}
                                data-testid={`DrawerListItem-${groupName}-${config.key}`}
                                href={replaceSlugs(config?.href ?? "", params)}
                                onClick={updatePathname}
                                drawerOpen={open}
                                icon={config.icon}
                                iconRotation={config.iconRotation}
                                text={config.label}
                                selected={
                                  config.key ===
                                  navConfig?.find(
                                    (config) => config?.isFallback,
                                  )?.key
                                    ? pathname ===
                                      replaceSlugs(
                                        // TODO: we should never cast with "as"
                                        (config?.href ?? "")
                                          .split("?")
                                          .at(0) as Route,
                                        params,
                                      )
                                    : pathname?.includes(
                                        replaceSlugs(
                                          // TODO: we should never cast with "as"
                                          (config?.href ?? "")
                                            .split("?")
                                            .at(0) as Route,
                                          params,
                                        ),
                                      )
                                }
                              />
                            ))}
                        </NavGroup>
                      ),
                    )}
                  </Stack>
                </Box>
              </DrawerPaper>
            </Drawer>
          )}
          <Box className="overflow-hidden flex-grow" data-testid="middle-part">
            {/* middle / main part of the screen */}
            <DrawerPaper elevation={0} square>
              <DrawerHeader size={useSimplifiedInterface ? "large" : "medium"}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                  flexGrow={1}
                  flexBasis="50%"
                >
                  {useSimplifiedInterface && (
                    <Link
                      href={replaceSlugs(
                        "/[locale]/cardealer/[cardealerId]",
                        params,
                        asideSearch,
                      )}
                    >
                      <Stack
                        spacing={theme.spacing(1)}
                        direction="row"
                        alignItems="center"
                      >
                        <LogoIcon
                          height={theme.spacing(2.5 * LOGO_SIZE_FACTOR)}
                        />
                        <LogoText height={theme.spacing(2.5)} />
                      </Stack>
                    </Link>
                  )}
                  {useSimplifiedInterface &&
                    !isServiceProviderDashboardRoute && (
                      <Link
                        href={replaceSlugs(
                          "/[locale]/cardealer/[cardealerId]",
                          params,
                          asideSearch,
                        )}
                      >
                        <Button
                          size="large"
                          startIcon={
                            <Icon
                              size={0.75 /* 18px */}
                              color={theme.palette.action.active}
                              path={mdiArrowLeft}
                            />
                          }
                          color="inherit"
                        >
                          {t("back.label")}
                        </Button>
                      </Link>
                    )}
                  {!useSimplifiedInterface && (
                    <AppBreadcrumbs
                      navConfig={allNavConfigs}
                      {...appBreadcrumbsProps}
                    />
                  )}
                </Stack>
                {isLawfirmUser && <AppGlobalSearch />}
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  flexGrow={1}
                  flexBasis="50%"
                >
                  {!useSimplifiedInterface && <MessageNotificationIconButton />}
                  {!useSimplifiedInterface && <NotificationIconButton />}
                  <UserAvatar
                    size={useSimplifiedInterface ? "large" : "medium"}
                  />
                </Stack>
              </DrawerHeader>
            </DrawerPaper>
            <main
              ref={contentRef}
              className="overflow-y-auto transition-all duration-300 ease-in-out"
              id={APP_LAYOUT_MAIN_CONTENT_ID}
              data-testid={APP_LAYOUT_MAIN_CONTENT_ID}
              style={{
                height: `calc(100vh - ${contentBoundingRect.top ?? 0}px)`,
                background:
                  background === "blueNoise"
                    ? `linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), radial-gradient(81.19% 46.14% at 50% 50%, rgba(245, 245, 245, 0.80) 0%, #F5F5F5 100%), url("${blueNoiseImage.src}") center / cover, lightgray 50% / cover no-repeat`
                    : "inherit",
              }}
            >
              {children}
            </main>
          </Box>
        </DrawerWrapper>
        <Collapse
          in={!!isAsideOpen && !asideOptions.has("hideAndKeepMounted")}
          className={"shrink-0 relative"}
          orientation="horizontal"
          style={{
            zIndex: theme.zIndex.modal,
          }}
          unmountOnExit={!asideOptions.has("hideAndKeepMounted")}
        >
          <Resizable
            defaultSize={{
              width: 512,
              height: "100%",
            }}
            maxWidth={Math.min(800, Math.max(windowSize.width ?? 0, 600) - 200)}
            minWidth={384}
            handleComponent={{ left: <DragHandler /> }}
            enable={horizontalResizeLeft}
          >
            <Paper
              // TODO: manipulate via drag
              data-testid="side-panel"
              component="aside"
              className={"h-[100vh] border-none"}
              elevation={0}
              square={true}
              variant="outlined"
            >
              <Suspense fallback={<AsideLoading />}>
                <Aside />
              </Suspense>
            </Paper>
          </Resizable>
        </Collapse>
      </div>
    </Stack>
  )
}
