"use client"

import BradcrumbItem from "@/app/_components/BreadcrumbItem"
import { Breadcrumbs } from "@/components/ui/navigation/Breadcrumbs"
import { getSlugNameByValue, replaceSlugs } from "@/utils/navigation"
import { mdiChevronRight } from "@mdi/js"
import Icon from "@mdi/react"
import type { Route } from "next"
import { useParams, usePathname } from "next/navigation"
import { useMemo } from "react"
import type { NavConfig, SubNavConfig } from "../AppPortalLayout"
import useAside from "../Aside/hooks/useAside"

export interface AppPortalBreadcrumbsProps {
  ignorePathnamePrefix?: string
  ignoreSubPaths?: string[]
  navConfig?: Array<NavConfig>
  subNavConfig?: Array<SubNavConfig>
  getTextBySubPath?: (subPathOrSlugName: string, slugValue?: string) => string
}

export default function AppPortalBreadcrumbs({
  ignorePathnamePrefix = "",
  ignoreSubPaths = [],
  navConfig = [],
  subNavConfig = [],
  getTextBySubPath,
}: AppPortalBreadcrumbsProps) {
  const pathname = usePathname()
  const params = useParams()
  const ignorePathnamePrefixString = replaceSlugs(ignorePathnamePrefix, params)
  const { asideSearch } = useAside()

  const getConfigByName = useMemo(
    () =>
      (name: string): NavConfig | undefined => {
        return (
          navConfig.find((config) => config.key === name) ||
          subNavConfig.find((config) => config.key === name)
        )
      },
    [navConfig, subNavConfig],
  )

  const getConfigBySlugName = useMemo(
    () =>
      (slugName: string): NavConfig | undefined => {
        const configItem =
          navConfig.find((config) => config.slugName === slugName) ||
          subNavConfig.find((config) => config.slugName === slugName)
        return configItem
      },
    [navConfig, subNavConfig],
  )

  const breadcrumbs = useMemo(() => {
    const subPaths = pathname
      ?.replace(new RegExp(`^${ignorePathnamePrefixString}`), "")
      ?.split("/")

    if (subPaths?.length) {
      subPaths.shift()
    }

    const breadcrumbs = subPaths
      .map((subPath, index) => {
        if (ignoreSubPaths.includes(subPath)) return null

        const slugName = getSlugNameByValue(params, subPath)
        const active = index === subPaths?.length - 1

        const href = `${ignorePathnamePrefixString}/${subPaths
          ?.filter((_, pathIndex) => pathIndex <= index)
          .join("/")}` as Route

        const config = slugName
          ? getConfigBySlugName(slugName)
          : getConfigByName(subPath)

        const text = slugName
          ? getTextBySubPath?.(slugName, subPath)
          : getTextBySubPath?.(subPath)

        return subPath
          ? {
              name: subPath,
              href,
              icon: config?.slugIcon ?? config?.icon,
              iconRotation: config?.iconRotation,
              text,
              active,
            }
          : null
      })
      .filter(Boolean)

    return breadcrumbs
  }, [
    getConfigByName,
    getConfigBySlugName,
    getTextBySubPath,
    ignorePathnamePrefixString,
    ignoreSubPaths,
    params,
    pathname,
  ])

  const fallbackNav = navConfig.find((config) => config?.isFallback)

  return (
    <Breadcrumbs
      data-testid="breadcrumbs"
      className="pl-1"
      separator={<Icon path={mdiChevronRight} size={0.75} />}
    >
      {breadcrumbs.map((breadcrumb) => (
        <BradcrumbItem
          key={`breadcrumb-${breadcrumb?.name}`}
          href={replaceSlugs(breadcrumb?.href ?? "", params, asideSearch)}
          icon={breadcrumb?.icon}
          iconRotation={breadcrumb?.iconRotation}
          text={breadcrumb?.text}
          active={breadcrumb?.active}
        />
      ))}
      {!breadcrumbs.length && fallbackNav && (
        <BradcrumbItem
          key="breadcrumb-fallback"
          href={replaceSlugs(fallbackNav?.href ?? "", params, asideSearch)}
          icon={fallbackNav?.icon}
          text={getTextBySubPath?.(fallbackNav?.key)}
          active
        />
      )}
    </Breadcrumbs>
  )
}
