import { useIntersectionObserver } from "@uidotdev/usehooks"
import useForceRerender from "./useForceRerender"

export default function useBoundingRect<T extends HTMLElement>(
  options: { reevaluateAfterMs?: number | false } = {},
): [
  (instance: T | null) => void,
  DOMRect,
  {
    reevaluate: () => void
  },
] {
  const reevaluate = useForceRerender(true, {
    delay: options.reevaluateAfterMs || 0,
    disable: !options.reevaluateAfterMs,
  })
  const [ref, entry] = useIntersectionObserver<T>()

  const boundingRect: DOMRect = entry?.target?.getBoundingClientRect() ?? {
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    toJSON: () => null,
  }

  return [
    ref,
    boundingRect,
    {
      reevaluate,
    },
  ]
}
